import Vue from 'vue'
import Router from 'vue-router'
import login from '../components/login.vue'
import home from '../components/home.vue'
import post from '../components/post.vue'
import user from '../components/user.vue'
import content from '../components/content.vue'
import search from '../components/search.vue'
import chat from '../components/chat.vue'
import pmlist from '../components/pmlist.vue'
import chatlist from '../components/chatlist.vue'
import functions from '../components/functions.vue'
import mobile from '../components/mobile.vue'

Vue.use(Router)

const router = new Router({

  routes : [
    { path:'/', redirect: '/home' },
    { path:'/login', component: login },
    { path:'/home',
      component: home,
      redirect: '/post',
      children: [
        { path:'/post', component: post },
        { path:'/user', component: user },
        { path:'/content', component: content },
        { path:'/search', component: search },
        { path:'/functions', component: functions },
        { path:'/chat', component: chat },
        { path:'/pmlist', component: pmlist },
        { path:'/chatlist', component: chatlist },
      ]
    },
    { path:'/m', component: mobile },
  ]
  
})

router.beforeEach( (to, from, next) => {
  if(to.path === '/login') return next()
  const token = window.sessionStorage.getItem('token')
  if(!token) return next('/login')
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  if (flag) {
    if(to.path === '/m'){
      return next()
    }else{
      return next('/m')
    }
  }
  next()
} )

export default router
