<template>
    <el-container class="home_container">
        <el-container>
            <el-aside width="80px">

                <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse" router>

                <el-menu-item index="post">
                    <i class="el-icon-bottom"></i>
                    <span slot="title">噗噗时间线</span>
                </el-menu-item>

                <el-menu-item index="content">
                    <i class="el-icon-document"></i>
                    <span slot="title">树洞</span>
                </el-menu-item>

                <el-menu-item index="user">
                    <i class="el-icon-menu"></i>
                    <span slot="title">用户</span>
                </el-menu-item>

                <el-menu-item index="search">
                    <i class="el-icon-search"></i>
                    <span slot="title">搜索</span>
                </el-menu-item>

                <el-menu-item index="chatlist">
                    <i class="el-icon-news"></i>
                    <span slot="title">私聊列表</span>
                </el-menu-item>

                <el-menu-item index="pmlist">
                    <i class="el-icon-chat-round"></i>
                    <span slot="title">私信记录列表</span>
                </el-menu-item>

                <el-menu-item index="functions">
                    <i class="el-icon-setting"></i>
                    <span slot="title">功能</span>
                </el-menu-item>
                
                </el-menu>

            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {
    data() {
      return {
        isCollapse: true
      };
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    }
  }
</script>

<style lang="less" scoped>
.home_container{
    height: 100%;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

</style>