<template>
<div>
    <h1>私聊{{chat_id}}</h1>

    <el-input placeholder="搜索" v-model="input" class="input-with-select" @keyup.enter.native="search">
    <el-select v-model="mode" slot="prepend" placeholder="请选择">
      <el-option label="树洞号" value="1"></el-option>
      <el-option label="用户ITSC" value="2"></el-option>
      <el-option label="关键词" value="3"></el-option>
    </el-select>
    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
  </el-input>

  <h4>私聊详情</h4>
    <el-table :data="chatList" style="width: 100%;margin-top:20px;" v-loading="chat_info_loading">

    <el-table-column label="聊天id" width="80">
      <template slot-scope="scope">
        <span>{{ scope.row.chat_id }}</span>
      </template>
    </el-table-column>

    <el-table-column label="发起者" width="100">
      <template slot-scope="scope">
        <el-tag size="medium" type="info" v-if="scope.row.sender_is_real_name=='0'" @click="nav2Sender(scope.row)">{{ scope.row.sender_itsc }}</el-tag>
        <el-tag size="medium" v-else @click="nav2Sender(scope.row)">{{ scope.row.sender_itsc }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="发起者名称" width="200">
      <template slot-scope="scope">
        <span>{{ scope.row.sender_alias }}</span>
      </template>
    </el-table-column>

    <el-table-column label="接收者" width="100">
      <template slot-scope="scope">
        <el-tag size="medium" type="info" v-if="scope.row.receiver_is_real_name=='0'" @click="nav2Receiver(scope.row)">{{ scope.row.receiver_itsc }}</el-tag>
        <el-tag size="medium" v-else @click="nav2Receiver(scope.row)">{{ scope.row.receiver_itsc }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="接收者名称" width="200">
      <template slot-scope="scope">
        <span>{{ scope.row.receiver_alias }}</span>
      </template>
    </el-table-column>

    <el-table-column label="聊天数量" width="100">
      <template slot-scope="scope">
        <span>{{ scope.row.pm_count }}</span>
      </template>
    </el-table-column>

    <el-table-column label="时间" width="100">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span>{{ scope.row.chat_date }}</span>
      </template>
    </el-table-column>

  </el-table>

    <h4>发起者信息</h4>
  <el-descriptions class="margin-top" style="width: 100%;" :column="3" border v-loading="sender_info_loading">
    <el-descriptions-item>
      <template slot="label">
        ITSC
      </template>
      <el-tag size="medium" @click="nav2User(senderInfo)">{{senderInfo.user_itsc}}</el-tag>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        Pootal ID
      </template>
      {{senderInfo.user_serial}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        昵称
      </template>
      {{senderInfo.user_nickname}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        姓名
      </template>
      {{senderInfo.user_name}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        禁言状态
      </template>
      {{senderInfo.user_silent_until}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        操作
      </template>
        <el-tag size="medium" type="danger" style="margin-left:10px" @click="silentSender('+ 1 hour')">禁言一小时</el-tag>
        <el-tag size="medium" type="danger" style="margin-left:10px" @click="silentSender('+ 1 day')">禁言一天</el-tag>
        <el-tag size="medium" type="danger" style="margin-left:10px" @click="silentSender('+ 7 day')">禁言一周</el-tag>
        <el-tag size="medium" type="success" style="margin-left:10px" @click="silentSender('- 1 minute')">解除禁言</el-tag>
    </el-descriptions-item>
  </el-descriptions>

  <h4>接收者信息</h4>
  <el-descriptions class="margin-top" style="width: 100%;" :column="3" border v-loading="receiver_info_loading">
    <el-descriptions-item>
      <template slot="label">
        ITSC
      </template>
      <el-tag size="medium" @click="nav2User(receiverInfo)">{{receiverInfo.user_itsc}}</el-tag>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        Pootal ID
      </template>
      {{receiverInfo.user_serial}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        昵称
      </template>
      {{receiverInfo.user_nickname}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        姓名
      </template>
      {{receiverInfo.user_name}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        禁言状态
      </template>
      {{receiverInfo.user_silent_until}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        操作
      </template>
        <el-tag size="medium" type="danger" style="margin-left:10px" @click="silentReceiver('+ 1 hour')">禁言一小时</el-tag>
        <el-tag size="medium" type="danger" style="margin-left:10px" @click="silentReceiver('+ 1 day')">禁言一天</el-tag>
        <el-tag size="medium" type="danger" style="margin-left:10px" @click="silentReceiver('+ 7 day')">禁言一周</el-tag>
        <el-tag size="medium" type="success" style="margin-left:10px" @click="silentReceiver('- 1 minute')">解除禁言</el-tag>
    </el-descriptions-item>
  </el-descriptions>

<h4>聊天记录</h4>
  <el-table :data="pmList" style="width: 100%;margin-top:20px;"  v-loading="pm_loading">

    <el-table-column label="私信id" width="80">
      <template slot-scope="scope">
        <span>{{ scope.row.pm_id }}</span>
      </template>
    </el-table-column>

    <el-table-column label="聊天id" width="80">
      <template slot-scope="scope">
        <span>{{ scope.row.chat_id }}</span>
      </template>
    </el-table-column>

    <el-table-column label="发送者" width="100">
      <template slot-scope="scope">
        <el-tag size="medium" type="danger" v-if="scope.row.sender_itsc==receiver_itsc" @click="nav2Sender(scope.row)">{{ scope.row.sender_itsc }}</el-tag>
        <el-tag size="medium" v-else @click="nav2Sender(scope.row)">{{ scope.row.sender_itsc }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="内容" width="600">
      <template slot-scope="scope">
        <span>{{ scope.row.pm_msg }}</span>
      </template>
    </el-table-column>

    <el-table-column label="时间" width="200">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span>{{ scope.row.pm_date }}</span>
      </template>
    </el-table-column>


  </el-table>
  <el-pagination background layout="prev, pager, next, jumper" @current-change="handlePmPageChange"></el-pagination>


  </div>
</template>

<script>
    export default{
        created(){
            this.chat_id = this.$route.query.chat_id
            this.sender_itsc = this.$route.query.sender_itsc
            this.receiver_itsc = this.$route.query.receiver_itsc
            this.getSenderInfo()
            this.getReceiverInfo()
            this.getChatInfo()
            this.getPm()
        },
        data(){
        return{
            chat_id:'',
            chatList:[],
            chat_info_loading:false,

            sender_itsc:'',
            senderInfo:[],
            sender_info_loading:false,

            receiver_itsc:'',
            receiverInfo:[],
            receiver_info_loading:false,

            pmList:[],
            pm_page:0,
            pm_loading:false,

            mode:'',
            input:''
        }
    },
        methods:{
            async getSenderInfo() {
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                this.sender_info_loading = true
                const {data:res} = await this.$http.post('user/info.php', "token="+token+"&user_itsc="+this.sender_itsc );
                console.log(res)
                this.sender_info_loading = false
                if (res.code == 0){
                    this.senderInfo = res.userInfo
                }else if (res.code == 1){
                    this.$message.error('用户不存在')
                }else if (res.code == 5){
                    this.$message.error('用户ITSC不得为空')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async getReceiverInfo() {
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                this.receiver_info_loading = true
                const {data:res} = await this.$http.post('user/info.php', "token="+token+"&user_itsc="+this.receiver_itsc );
                console.log(res)
                this.receiver_info_loading = false
                if (res.code == 0){
                    this.receiverInfo = res.userInfo
                }else if (res.code == 1){
                    this.$message.error('用户不存在')
                }else if (res.code == 5){
                    this.$message.error('用户ITSC不得为空')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async getChatInfo() {
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                this.chat_info_loading = true
                const {data:res} = await this.$http.post('chat/info.php', "token="+token+"&chat_id="+this.chat_id );
                console.log(res)
                this.chat_info_loading = false
                if (res.code == 0){
                    this.chatList = [res.chatInfo]
                }else if (res.code == 5){

                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async getPm() {
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                this.pm_loading = true
                const {data:res} = await this.$http.post('chat/pm.php', "token="+token+"&chat_id="+this.chat_id+"&page="+this.pm_page );
                console.log(res)
                this.pm_loading = false
                if (res.code == 0){
                    this.pmList = res.pmList
                }else if (res.code == 5){

                }
                else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            handlePostPageChange (page){
                this.post_page = page - 1
                this.postList = []
                this.getPost()
            },
            handleCommentPageChange (page){
                this.comment_page = page - 1
                this.commentList = []
                this.getComment()
            },
            handlePmPageChange (page){
                this.pm_page = page - 1
                this.pmList = []
                this.getPm()
            },
            async silentSender (e){
                const user_itsc = this.senderInfo.user_itsc
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('user/silent.php', "token="+token+"&user_itsc="+user_itsc+"&silent_time="+e );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('操作成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async silentReceiver (e){
                const user_itsc = this.receiverInfo.user_itsc
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('user/silent.php', "token="+token+"&user_itsc="+user_itsc+"&silent_time="+e );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('操作成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            nav2Post(e){
                const routeUrl = this.$router.resolve('/content?post_id=' + e.post_id)
                window.open(routeUrl .href, '_blank');
            },
            nav2User(e){
                const routeUrl = this.$router.resolve('/user?user_itsc=' + e.user_itsc)
                window.open(routeUrl .href, '_blank');
            },
            nav2Sender(e){
                const routeUrl = this.$router.resolve('/user?user_itsc=' + e.sender_itsc)
                window.open(routeUrl .href, '_blank');
            },
            nav2Receiver(e){
                const routeUrl = this.$router.resolve('/user?user_itsc=' + e.receiver_itsc)
                window.open(routeUrl .href, '_blank');
            },
            nav2Chat(e){
                const routeUrl = this.$router.resolve('/chat?chat_id=' + e.chat_id + '&sender_itsc=' + e.sender_itsc + '&receiver_itsc=' + e.receiver_itsc)
                window.open(routeUrl .href, '_blank');
            },
            search(){
              if(this.mode == 1){
                const e = {"post_id":this.input}
                this.nav2Post(e)
              }else if(this.mode == 2){
                const e = {"user_itsc":this.input}
                this.nav2User(e)
              }
              else if(this.mode == 3){
                const routeUrl = this.$router.resolve('/search?search_text=' + this.input)
                window.open(routeUrl .href, '_blank');
              }
            }
        }
    }
</script>

<style lang="less" scoped>
.el-table .cell {
    padding-right: 0;
}
</style>