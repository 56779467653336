<template>
<div>
    <h1>私聊列表</h1>

    <el-input placeholder="搜索" v-model="input" class="input-with-select" @keyup.enter.native="search">
    <el-select v-model="mode" slot="prepend" placeholder="请选择">
      <el-option label="树洞号" value="1"></el-option>
      <el-option label="用户ITSC" value="2"></el-option>
      <el-option label="关键词" value="3"></el-option>
    </el-select>
    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
  </el-input>

    <el-table :data="chatList" style="width: 100%;margin-top:20px;" v-loading="chat_loading">

    <el-table-column label="聊天id" width="80">
      <template slot-scope="scope">
        <span  @click="nav2Chat(scope.row)">{{ scope.row.chat_id }}</span>
      </template>
    </el-table-column>

    <el-table-column label="发起者" width="100">
      <template slot-scope="scope">
        <el-tag size="medium" type="info" v-if="scope.row.sender_is_real_name=='0'" @click="nav2Sender(scope.row)">{{ scope.row.sender_itsc }}</el-tag>
        <el-tag size="medium" v-else @click="nav2Sender(scope.row)">{{ scope.row.sender_itsc }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="发起者名称" width="200">
      <template slot-scope="scope">
        <span @click="nav2Chat(scope.row)">{{ scope.row.sender_alias }}</span>
      </template>
    </el-table-column>

    <el-table-column label="接收者" width="100">
      <template slot-scope="scope">
        <el-tag size="medium" type="info" v-if="scope.row.receiver_is_real_name=='0'" @click="nav2Receiver(scope.row)">{{ scope.row.receiver_itsc }}</el-tag>
        <el-tag size="medium" v-else @click="nav2Receiver(scope.row)">{{ scope.row.receiver_itsc }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="接收者名称" width="200">
      <template slot-scope="scope">
        <span @click="nav2Chat(scope.row)">{{ scope.row.receiver_alias }}</span>
      </template>
    </el-table-column>

    <el-table-column label="聊天数量" width="100">
      <template slot-scope="scope">
        <span @click="nav2Chat(scope.row)">{{ scope.row.pm_count }}</span>
      </template>
    </el-table-column>

    <el-table-column label="时间" width="100">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span>{{ scope.row.chat_date }}</span>
      </template>
    </el-table-column>

  </el-table>
  <el-pagination background layout="prev, pager, next, jumper" @current-change="handleChatPageChange"></el-pagination>

  </div>
</template>

<script>
    export default{
        created(){
            this.getChatList()
        },
        data(){
        return{

            chatList:[],
            chat_page:0,
            chat_loading:false,

            mode:'',
            input:''
        }
    },
        methods:{
            async getChatList() {
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                this.chat_loading = true
                const {data:res} = await this.$http.post('chat/chatlist.php', "token="+token+"&page="+this.chat_page );
                console.log(res)
                this.chat_loading = false
                if (res.code == 0){
                    this.chatList = res.chatList
                }else if (res.code == 5){

                }
                else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            handleChatPageChange (page){
                this.chat_page = page - 1
                this.chatList = []
                this.getChatList()
            },
            nav2Post(e){
                const routeUrl = this.$router.resolve('/content?post_id=' + e.post_id)
                window.open(routeUrl .href, '_blank');
            },
            nav2User(e){
                const routeUrl = this.$router.resolve('/user?user_itsc=' + e.user_itsc)
                window.open(routeUrl .href, '_blank');
            },
            nav2Sender(e){
                const routeUrl = this.$router.resolve('/user?user_itsc=' + e.sender_itsc)
                window.open(routeUrl .href, '_blank');
            },
            nav2Receiver(e){
                const routeUrl = this.$router.resolve('/user?user_itsc=' + e.receiver_itsc)
                window.open(routeUrl .href, '_blank');
            },
            nav2Chat(e){
                const routeUrl = this.$router.resolve('/chat?chat_id=' + e.chat_id + '&sender_itsc=' + e.sender_itsc + '&receiver_itsc=' + e.receiver_itsc)
                window.open(routeUrl .href, '_blank');
            },
            search(){
              if(this.mode == 1){
                const e = {"post_id":this.input}
                this.nav2Post(e)
              }else if(this.mode == 2){
                const e = {"user_itsc":this.input}
                this.nav2User(e)
              }
              else if(this.mode == 3){
                const routeUrl = this.$router.resolve('/search?search_text=' + this.input)
                window.open(routeUrl .href, '_blank');
              }
            }
        }
    }
</script>

<style lang="less" scoped>
.el-table .cell {
    padding-right: 0;
}
</style>